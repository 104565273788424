/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile {
    getProfile {
      email
      name
      family_name
      company
      xero_contact_id
      answer {
        question
        options
        selected
        type
      }
    }
  }
`;
export const getBookmark = /* GraphQL */ `
  query GetBookmark {
    getBookmark
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $country: [String!]
    $postal_region: [String!]
    $postal_district: [Int!]
    $pv_capacity_min: Float
    $pv_capacity_max: Float
    $lot_lease: [String!]
    $lot_owner_type: [String!]
    $sort: SORT
    $sort_field: SORT_FIELD
    $from: Int!
    $size: Int!
  ) {
    listLocations(
      country: $country
      postal_region: $postal_region
      postal_district: $postal_district
      pv_capacity_min: $pv_capacity_min
      pv_capacity_max: $pv_capacity_max
      lot_lease: $lot_lease
      lot_owner_type: $lot_owner_type
      sort: $sort
      sort_field: $sort_field
      from: $from
      size: $size
    ) {
      total
      pv_capacity
      locations {
        id
        pv_capacity
        expiry
        postal_region
        postal_district_no
        lot_owner_type
        tenure
      }
    }
  }
`;
export const listLocationsByIds = /* GraphQL */ `
  query ListLocationsByIds(
    $ids: [ID!]!
    $from: Int!
    $size: Int!
    $sort: SORT
    $sort_field: SORT_FIELD
  ) {
    listLocationsByIds(
      ids: $ids
      from: $from
      size: $size
      sort: $sort
      sort_field: $sort_field
    ) {
      total
      pv_capacity
      locations {
        id
        pv_capacity
        expiry
        postal_region
        postal_district_no
        lot_owner_type
        tenure
      }
    }
  }
`;
