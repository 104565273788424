/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile($input: ProfileInput!) {
    updateProfile(input: $input) {
      email
      name
      family_name
      company
      xero_contact_id
      answer {
        question
        options
        selected
        type
      }
    }
  }
`;
export const updateBookmark = /* GraphQL */ `
  mutation UpdateBookmark($input: [String]!) {
    updateBookmark(input: $input)
  }
`;
export const checkout = /* GraphQL */ `
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      id
    }
  }
`;
